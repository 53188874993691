.Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
}

.Footer div {
  margin-right: 20px;
}

.Footer h4 {
  margin-bottom: 10px;
}

.Footer a {
  text-decoration: none;
  color: #fff;
}

.Footer a:hover {
  text-decoration: underline;
}